/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@use "node_modules/@ionic/angular/css/core.css" as *;
@use "node_modules/@ionic/angular/css/display.css" as *;
@use "node_modules/@ionic/angular/css/flex-utils.css" as *;
@use "node_modules/@ionic/angular/css/float-elements.css" as *;
/* Basic CSS for apps built with Ionic */
@use "node_modules/@ionic/angular/css/normalize.css" as *;
@use "node_modules/@ionic/angular/css/structure.css" as *;
@use "node_modules/@ionic/angular/css/typography.css" as *;

/* Optional CSS utils that can be commented out */
@use "node_modules/@ionic/angular/css/padding.css" as *;
@use "node_modules/@ionic/angular/css/structure.css" as *;
@use "node_modules/@ionic/angular/css/text-alignment.css" as *;
@use "node_modules/@ionic/angular/css/text-transformation.css" as *;
@use "node_modules/@ionic/angular/css/typography.css" as *;
/* ngx-spinner */
@use "node_modules/ngx-spinner/animations/ball-clip-rotate-multiple.css" as *;
@use "./environments/index.scss" as *;
@use "variables" as *;
@use "./global-ion-custom.scss" as *;

ion-alert {
    color: green;
    --color: red;
}

/* Live- and HistoryComponent*/
ion-refresher-content {
    ion-spinner {
        left: 0 !important;
    }
}

ion-card-content {
    color: var(--ion-card-content-color);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    background-color: transparent !important;
    box-shadow: 0 0 0em 1000em var(--ion-background-color) inset !important;
    -webkit-text-fill-color: var(--ion-text-color) !important;
    margin-top: 0.1em;
}

.custom-ion-popover {

    white-space: inherit;

    // Overwrites default width of popover
    --min-width: min-content;
}

.footer-color {
    background-color: var(--ion-color-toolbar-secondary) !important;
}

.hide-empty-list-header {

    // Guarantees evenly space between list-items
    ion-list {
        padding: 0;
    }

    ion-list-header {
        display: none;
    }

}

.disabled {
    color: gray;
    pointer-events: none;
    opacity: 0.5;
    /* Makes it semi-transparent */
}

formly-wrapper-ion-form-field,
formly-input-serial-number,
formly-field-ion-radio {
    ion-label {
        line-height: 1.2;
        font-size: 0.6em;

        span {
            white-space: initial;

            small {
                white-space: nowrap;
            }
        }

        slot {
            white-space: initial;
        }
    }
}

.custom-spinner {
    ion-spinner {
        left: 0 !important;
    }
}

.normal-icon {
    color: var(--ion-color-primary-shade) !important;

    ion-icon {
        color: var(--ion-color-primary-shade) !important;
    }
}

.percentage-style {
    fill: var(--ion-color-text-percentage-bar);
    text-shadow: var(--ion-text-shadow);
}

.ion-card-style {
    ion-item {
        --background: var(--ion-color-primary);

        ion-icon {
            color: var(--ion-color-primary-contrast);
        }
    }
}

.custom-channels-text {
    color: var(--ion-color-text-channels) !important;
}

//* Color of Menu button *//
.custom-menu-button {
    color: var(--ion-menu-color);
}

.custom-theme-text {
    color: var(--ion-color-text-menu) !important;
}

.custom-menu-text-dark {
    color: var(--ion-color-text-menu-dark) !important;
}

.item-devider {
    border-color: white;
}

.custom-ion-popover {

    --border-width: 0;
    --background: var(--ion-background-color);

    // Floating label
    &::part(label) {
        color: var(--ion-color-primary) !important;
    }

    // Overwrites default width of popover
    white-space: inherit;
    --min-width: min-content;

    [aria-checked="true"] {
        .alert-radio-label {
            color: var(--ion-color-text) !important;
        }
    }

    .alert-radio-label {
        color: var(--ion-color-text);
    }

    // Color of the selected Language from the list.
    .item-radio-checked {
        --background: var(--ion-color-primary-tint);
        --background-opacity: 0.9;
    }
}

.line-break {

    formly-wrapper-ion-form-field>ion-item>ion-label>span>small,
    formly-field-ion-radio>ion-list>ion-radio-group>ion-item>ion-label {
        white-space: pre-line !important;
        line-height: 1.6 !important;
    }
}

// Removes the ripple effect while using the custom box.
.custom-checkbox {
    --ripple-color: transparent;
    --background-hover: transparent;
}

.command-textbox {
    font-family: monospace;
    color: var(--ion-color-text);
}

.margin-top {
    ion-item {
        margin-top: 2%;
    }
}

.active {
    color: var(--ion-color-dark);
}

.primary-color {
    fill: $primary-color;
}

.primary-color-contrast {
    fill: $primary-color-contrast;
}

.secondary-color {
    fill: $secondary-color;
}

.secondary-color-contrast {
    fill: $secondary-color-contrast;
}

html.ios {
    --ion-default-font: -apple-system, BlinkMacSystemFont, $font-family;
}

html.md {
    --ion-default-font: $font-family;
}

//252px for the exact width of my-date-range-picker in PickDateComponent
.pickdate-popover .popover-content {
    width: 251px;
    position: center;
    overflow-y: hidden;
    overflow-x: hidden;
}

.slides {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    padding: 0 1% 1% 0;

    .swiper-wrapper {
        align-items: center;
    }
}

.white-space-initial {
    white-space: initial !important;
}

ion-label {
    font-family: Arial, Helvetica, sans-serif;
}

ion-item.noPadding {
    --ion-safe-area-right: 0.1;
}

mat-card.single {
    margin: 20px;
}

mat-card.single_leftright {
    margin-left: 20px;
    margin-right: 20px;
}

mat-card.single_top {
    margin-top: 20px;
}

table.full_width {
    width: 100%;
}

.bold {
    font-weight: 600;
}

td.align_right {
    text-align: right;
}

table {
    border-collapse: collapse;
}

.underline {
    border-bottom: 1pt solid var(--ion-color-medium);
}

.gray_color {
    color: var(--ion-color-medium);
}

button.activated {
    color: var(--ion-color-dark);
    background-color: var(--ion-color-success);
}

svg::part('container') {
    --border-color: white;
    --checkmark-color: red;
}

ion-item,
ion-checkbox {
    .checkbox-icon {
        display: none;
    }
}

#theme-switcher {
    --transition: checkmark-color 5s ease-in-out;

    :host {
        --checkmark-color: red;
    }

    ion-checkbox::part('mark') {
        --border-color: white;
        --checkmark-color: red;
    }
}

#toast-container {
    font-family: $font-family;
}

// 360px is approximate min. width of mobile devices
ion-toast.container {
    text-align: center;
    --min-width: 360px;
}

.no-scroll {
    overflow-y: hidden;
    overflow-x: hidden;
}

/*
* Applies to ngx-formly sections
*/
formly-input-section {
    flex: auto; // use all remaining space in a row
}

// style to color table rows with translusent background
.even-row {
    background-color: rgba(var(--ion-color-primary-rgb), 0.2);
}

// Added styles for alertController, because alertController doesn't take ionic default style
.alertController {
    .alert-head {
        text-align: center !important;

        .alert-sub-title {
            font-size: large;
            font-weight: bold;
            color: $primary-color;
        }
    }

    .alert-message {
        text-align: center;
    }

    .alert-wrapper {
        width: 25% !important;

        .alert-button-group {
            justify-content: space-between;

            .alert-button {
                color: $primary-color;
                font-size: small;
            }
        }
    }
}

:root {

    ion-text {
        small {
            color: var(--ion-card-content-color);
        }
    }

    ion-card {

        ion-card-title,
        ion-card-subtitle {
            color: var(--ion-text-color);
        }
    }

    --ion-color-primary-item: shade($primary-color, #000);

    ion-segment {
        --color: var(--ion-text-color);
        color: var(--ion-text-color);
    }

    // Used in IBN, dynamicFeedInLimitation
    formly-field-ion-select {
        ion-select::part(text) {
            white-space: initial !important;
        }
    }

    // Used in GridOptimizedCharge & Heatingelement, because ion-picker is not accessible via ion-datetime tag
    ion-picker {
        justify-content: center !important;
        max-width: 100% !important;
    }

    // CSS class is here because ion-label is inside ion-item tag, CSS within ion-item doesn't work with global.scss
    // this class is used to get the same style like in a html table
    ion-label.regularFont {
        font-family: $font-family;
        font-size: 14px;
    }

    // CSS class is here because ion-label is inside ion-item tag, CSS within ion-item doesn't work with global.scss
    // this class is used to get the same style like in a html table
    ion-label.regularFont {
        font-family: $font-family;
        font-size: 14px;
    }

    // CSS class is used to get the same style like in a html table
    ion-select {
        font-family: $font-family;
        font-size: 14px;
        color: var(--ion-text-color);
    }

    ion-alert {
        color: var(--ion-text-color) !important;
        --color: red;

        [aria-checked="true"] {
            .alert-radio-label {
                color: var(--ion-color-primary) !important;
            }
        }

        .alert-radio-label {
            color: var(--ion-text-color);
        }
    }

    .alert-wrapper {
        max-width: 100%;
        width: 100%;
    }

    ion-input.regularFont {
        font-family: $font-family;
        font-size: 14px;
    }

    ion-toggle {
        --track-background: var(--ion-color-toggle-background);
        --track-background-checked: var(--ion-color-toggle-background);
    }

    ion-spinner {
        transform: scale(1.5);
        left: 25%;
        width: 50%;
        color: var(--ion-color-primary);
    }

    // Ensure content is fitting inside tooltip
    ion-range::part(pin) {
        width: max-content !important;
        white-space: nowrap;
        padding: 0.5em;
        text-align: center;
    }
}

// auto height for modals
ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
}

ion-modal.full-width {
    --width: 100%;
}

/** SVG Variables **/
:root {
    --storage-segment-0: block;
    --storage-segment-1: block;
    --storage-segment-2: block;
    --storage-segment-3: block;
    --storage-segment-4: none;
}

.storage-0 {
    --storage-segment-0: none;
    --storage-segment-1: none;
    --storage-segment-2: none;
    --storage-segment-3: none;
    --storage-segment-4: none;
}

.storage-20 {
    --storage-segment-0: block;
    --storage-segment-1: none;
    --storage-segment-2: none;
    --storage-segment-3: none;
    --storage-segment-4: none;
}

.storage-40 {
    --storage-segment-0: block;
    --storage-segment-1: block;
    --storage-segment-2: none;
    --storage-segment-3: none;
    --storage-segment-4: none;
}

.storage-60 {
    --storage-segment-0: block;
    --storage-segment-1: block;
    --storage-segment-2: block;
    --storage-segment-3: none;
    --storage-segment-4: none;
}

.storage-80 {
    --storage-segment-0: block;
    --storage-segment-1: block;
    --storage-segment-2: block;
    --storage-segment-3: block;
    --storage-segment-4: none;
}

.storage-100 {
    --storage-segment-0: block;
    --storage-segment-1: block;
    --storage-segment-2: block;
    --storage-segment-3: block;
    --storage-segment-4: block;
}

.card-with-primary-border {
    border: 2px solid $primary-color;
}

ion-avatar.iconify {
    margin-inline-end: 32px;
    margin-top: 12px;
    margin-bottom: 12px;
    --border-radius: 0;
    max-height: 24px;
    max-width: 24px;
}

ion-avatar.iconify.icon-large {
    max-height: 32px;
    max-width: 32px;
}